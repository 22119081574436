import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../models/category';
import { ContextService } from '../../services/context.service';

@Component({
    selector: 'app-header-category-list',
    templateUrl: './header-category-list.component.html',
    styleUrls: ['./header-category-list.component.scss'],
})
export class HeaderCategoryListComponent implements OnInit {
    protected displayCategories: Category[] = [];
    @Input() set categories(categories: Category[]) {
        console.log(categories);
        // Remove categories with offer count = 0
        const platform = this.contextService.getPlatform();
        this.displayCategories = categories.filter((c) => c.hasOffersOnPlatform(platform) && c.isPlatform(platform));
        console.log(this.displayCategories);
    }
    @Output() selectedCategory = new EventEmitter<Category>();

    constructor(private readonly contextService: ContextService) {}

    ngOnInit() {}

    select(category: Category) {
        this.selectedCategory.emit(category);
    }
}
