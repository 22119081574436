import Decimal from 'decimal.js';
import { CategoryUtils } from '../utils/category.utils';
import { Company } from './company';
import { Filter } from './filter';
import { Media } from './media';
import { OfferDefinition, OfferVariant, OfferVariantPost, OfferVariantValuePost } from './offer-definition';
import { AppPlatform } from './platform';
import { SizeGuide } from './size-guide';
import { Address, User } from './user';
import { Vat } from './vat';

export type GoodType = 'physical' | 'digital' | 'service' | 'rental' | 'subscription' | 'donation' | 'exchange' | 'hiring';

export class OfferGroup {
    id: number;
    offers: Offer[] = [];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export interface OfferInterface {
    platform: AppPlatform;
    offerGroup: number;
    category: number;
    name: string;
    description: string;
    /** @deprecated use referencePrice instead */
    price?: number;
    referencePrice: number;
    referenceCurrency: string;
    vat: number;
    address: number;
    country: string;
    sizeGuide: number;
    definitions: OfferDefinition[];
    variants: OfferVariantInterface[];
    medias: number[];
    packages: CreateOfferPackage[];
    wholesalePrices?: OfferWholesaleLine[];
    minWholesaleQuantity?: number;
    daysToShip?: number;
    stocks?: number;
}

export interface CreateOfferPackage {
    weight: number;
    deci: string;
}

export interface OfferWholesaleLine {
    min: number;
    max: number;
    price: number;
}

export interface OfferVariantInterface {
    price: string; // If not set, use offer price
    stocks?: number;
    sku: string;
    definitions: {
        filter: number; // filter id
        textValue: string;
        integerValue: number;
        filterValues: number[]; // filter values ids
    }[];
}

export type OfferVariantGroup = {
    code: string;
    filter: Filter;
    value: OfferVariantValuePost;
    variants: OfferVariantPost[];
    medias: Media[];
};

export class OfferTranslation {
    id: number;
    name: string;
    description: string;
    locale: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

class WholesalePrice {
    id: number;
    min: number;
    max: number;
    price: string;
    currency: string;

    constructor(data?: any, currency?: string) {
        if (data) {
            Object.assign(this, data);
        }
        if (currency) {
            this.currency = currency;
        }
    }

    get priceObject(): PriceObject {
        return {
            price: new Decimal(this.price),
            currency: this.currency,
            from: false,
        };
    }
}

export class PriceObject {
    price: Decimal;
    currency: string;
    from?: boolean;
}

export class Offer {
    id: number;
    name: string;
    description: string;
    price: string;
    createdAt: string;
    region: string;
    city: string;
    medias: Media[];
    cover: Media = null;
    slug: string;
    isPublished: boolean;
    user: User;
    company: Company;
    translations: OfferTranslation[] = [];
    definitions: OfferDefinition[] = [];
    category: number; // category id
    type: GoodType = 'physical';
    packages: OfferPackage[] = [];
    packageCount: number = 0;
    address: Address;
    referencePrice: string;
    referenceCurrency: string;
    minWholesaleQuantity: number;
    wholesalePrices: WholesalePrice[] = [];
    country: string;
    vat: Vat;
    offerGroup: number;
    variants: OfferVariant[] = [];
    platform: 'gazette' | 'marketplace' | 'wholesale';
    softDeletedAt: string;
    sizeGuide: SizeGuide;
    daysToShip: number;
    stocks: number;
    stocksPending: number;
    totalStocks: number = 0;
    totalStocksPending: number = 0;
    sku: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.medias = data.medias?.map((media: any) => new Media(media)) || [];
            this.cover = this.medias.length > 0 ? this.medias[0] : null;
            this.user = data.user ? new User(data.user) : null;
            this.company = data.company ? new Company(data.company) : null;
            this.translations = (data?.translations || []).map((t: any) => new OfferTranslation(t));
            this.definitions = (data?.definitions || []).map((d: any) => new OfferDefinition(d));
            this.packages = (data?.packages || []).map((p: any) => new OfferPackage(p));
            this.address = data.address ? new Address(data.address) : null;
            this.vat = data.vat ? new Vat(data.vat) : null;
            this.variants = (data?.variants || []).map((v: any) => new OfferVariant(v));
            this.sizeGuide = data.sizeGuide ? new SizeGuide(data.sizeGuide) : null;
            this.wholesalePrices = (data?.wholesalePrices || []).map((w: any) => new WholesalePrice(w, this.referenceCurrency));
        }
    }

    computePrice(wholesale = false, quantity: number = 1, variantId: number = null): PriceObject {
        // If wholesale, return the wholesale price (lowest price)
        if (wholesale && this.wholesalePrices.length > 0) {
            // Find price for the given quantity
            const wholesalePrice = this.wholesalePrices.find((w) => quantity >= w.min && quantity <= w.max);
            console.log('wholesalePrices', this.wholesalePrices);
            return {
                price: new Decimal(wholesalePrice.price).mul(quantity),
                currency: this.referenceCurrency,
                from: true,
            };
        }

        // If variant, return the variant price
        if (variantId) {
            const variant = this.variants.find((v) => v.id === variantId);
            if (variant && variant.price) {
                const variantPrice = new Decimal(variant.price);
                if (variantPrice.greaterThan(0)) {
                    return {
                        price: variantPrice.mul(quantity),
                        currency: this.referenceCurrency,
                        from: false,
                    };
                }
            }
        }

        // Use reference price if available
        if (this.referencePrice && this.referenceCurrency) {
            return {
                price: new Decimal(this.referencePrice).mul(quantity),
                currency: this.referenceCurrency,
                from: false,
            };
        }

        // Use price
        return {
            price: new Decimal(this.price).mul(quantity),
            currency: null,
            from: false,
        };
    }

    get currency(): string {
        return this.referenceCurrency || 'TRY';
    }

    get priceObject(): PriceObject {
        return {
            price: new Decimal(this.referencePrice || this.price || 0),
            currency: this.referenceCurrency,
            from: false,
        };
    }

    get categoryName(): string {
        return CategoryUtils.getById(this.category)?.name;
    }
}

export class OfferPackage {
    id: number;
    weight: number;
    deci: Decimal;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class Stock {
    id: number;
    quantity: number;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export interface ShippingInfos {
    id: number;
    minDeliveryPrice: PriceObject;
    minDeliveryDate: string;
}
