import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddressListComponent } from './components/address-list/address-list.component';
import { AddressComponent } from './components/address/address.component';
import { CompanyProfileCardComponent } from './components/company-profile-card/company-profile-card.component';
import { CustomFormModule } from './components/custom-form.module';
import { FilterModule } from './components/filters/filter.module';
import { FilterVariantComponent } from './components/filters/forms/filter-variant/filter-variant.component';
import { FooterMobileComponent } from './components/footer-mobile/footer-mobile.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderCategoryListComponent } from './components/header-category-list/header-category-list.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LocaleSettingsComponent } from './components/locale-settings/locale-settings.component';
import { NotShippableOfferComponent } from './components/offer/not-shippable-offer/not-shippable-offer.component';
import { ShippableOfferComponent } from './components/offer/shippable-offer/shippable-offer.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PhoneNumberFormComponent } from './components/phone-number-form/phone-number-form.component';
import { PictureComponent } from './components/picture/picture.component';
import { PriceComponent } from './components/price/price.component';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { SearchPlaceComponent } from './components/search-place/search-place.component';
import { ShippingInfoComponent } from './components/shipping-info/shipping-info.component';
import { TeamAccountMemberComponent } from './components/team-account-member/team-account-member.component';
import { TeamAccountRequestComponent } from './components/team-account-request/team-account-request.component';
import { TodoListComponent } from './components/todo-list/todo-list.component';
import { UserProfileCardComponent } from './components/user-profile-card/user-profile-card.component';
import { VariantNameComponent } from './components/variant-name/variant-name.component';
import { AddressModalComponent } from './modals/address-modal/address-modal.component';
import { CompanyModalComponent } from './modals/company-modal/company-modal.component';
import { FilterModalComponent } from './modals/filter-modal/filter-modal.component';
import { PasswordLostModalComponent } from './modals/password-lost-modal/password-lost-modal.component';
import { PickAddressModalComponent } from './modals/pick-address-modal/pick-address-modal.component';
import { SearchFilterModalComponent } from './modals/search-filter-modal/search-filter-modal.component';
import { SettingsModalComponent } from './modals/settings-modal/settings-modal.component';
import { TeamAccountApproveModalComponent } from './modals/team-account-approve-modal/team-account-approve-modal.component';
import { UpdateOfferModalComponent } from './modals/update-offer-modal/update-offer-modal.component';
import { CountryPipe } from './pipes/country.pipe';
import { GetUrlPipe } from './pipes/get-url.pipe';
import { TagNamePipe } from './pipes/tag-name.pipe';

@NgModule({
    declarations: [
        HeaderComponent,
        SearchFormComponent,
        FooterComponent,
        GetUrlPipe,
        PictureComponent,
        ImageUploadComponent,
        TeamAccountRequestComponent,
        TeamAccountMemberComponent,
        TeamAccountApproveModalComponent,
        AddressModalComponent,
        PriceComponent,
        UserProfileCardComponent,
        CompanyProfileCardComponent,
        LoaderComponent,
        CountryPipe,
        HeaderCategoryListComponent,
        SearchFilterModalComponent,
        PhoneNumberFormComponent,
        SearchPlaceComponent,
        AddressListComponent,
        TagNamePipe,
        CompanyModalComponent,
        QuantitySelectorComponent,
        FooterMobileComponent,
        LocaleSettingsComponent,
        SettingsModalComponent,
        PickAddressModalComponent,
        FilterVariantComponent,
        ShippableOfferComponent,
        NotShippableOfferComponent,
        OrderSummaryComponent,
        VariantNameComponent,
        AddressComponent,
        PasswordLostModalComponent,
        PaginationComponent,
        FilterModalComponent,
        TodoListComponent,
        UpdateOfferModalComponent,
        ShippingInfoComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgOptimizedImage,
        AsyncPipe,
        ReactiveFormsModule,
        TranslateModule,
        IonicModule,
        FilterModule,
        CustomFormModule,
        FormsModule,
    ],
    exports: [
        HeaderComponent,
        SearchFormComponent,
        FooterComponent,
        GetUrlPipe,
        PictureComponent,
        ImageUploadComponent,
        TeamAccountRequestComponent,
        TeamAccountMemberComponent,
        AddressModalComponent,
        PriceComponent,
        UserProfileCardComponent,
        CompanyProfileCardComponent,
        LoaderComponent,
        SearchFilterModalComponent,
        SearchPlaceComponent,
        AddressListComponent,
        TagNamePipe,
        PhoneNumberFormComponent,
        CompanyModalComponent,
        QuantitySelectorComponent,
        FooterMobileComponent,
        LocaleSettingsComponent,
        SettingsModalComponent,
        PickAddressModalComponent,
        FilterVariantComponent,
        ShippableOfferComponent,
        NotShippableOfferComponent,
        OrderSummaryComponent,
        AddressComponent,
        PasswordLostModalComponent,
        PaginationComponent,
        FilterModalComponent,
        TodoListComponent,
        UpdateOfferModalComponent,
        ShippingInfoComponent,
    ],
    bootstrap: [],
})
export class SharedModule {}
